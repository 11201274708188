import Vue from 'vue';
import App from './App.vue';
import router from './router';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './styles/style.scss';
import './styles/index.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
const pinia = createPinia()
Vue.use(PiniaVuePlugin)
Vue.use(VueCompositionAPI)

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
// 导入axios
// import axios from 'axios'
// import axiosUtils from '@/utils/request'
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.withCredentials = true
// let http = axios.create({
//   baseURL: '/api/admin',
//   timeout: 10000
// });
// axiosUtils.httpRequest(http);
// Vue.prototype.$http = http
//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

import 'default-passive-events'
import animated from 'animate.css'
Vue.use(animated)
// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);


new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
