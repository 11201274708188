import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/chronic/login.vue'),
  },
  {
    path: '/ecg',
    component: () => import('@/views/ecg/index.vue'),
  },
  {
    path: '/chronic',
    component: () => import('@/views/chronic/index.vue'),
  },
  {
    path: '/particulars',
    component: () => import('@/views/chronic/particulars.vue'),
  },
  {
    path: '/report',
    component: () => import('@/views/chronic/report.vue'),
  },
]
const router = new VueRouter({
  mode: "hash",
  routes
})

export default router